import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const Container = styled.div`
  background-color: ${({ backgroundColor, theme: { colors } }) => colors[backgroundColor]};
  position: relative;
  z-index: 1;
  overflow-x: hidden;
`;

const RelativeWrapper = ({ children, backgroundColor }) => (
  <Container backgroundColor={backgroundColor}>
    {children}
  </Container>
);

RelativeWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  backgroundColor: PropTypes.string,
};

RelativeWrapper.defaultProps = {
  backgroundColor: 'transparent',
};

export default RelativeWrapper;
